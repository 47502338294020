import { EnumsBase } from './-base';

export const KpiTypes = [
  'TOTAL_INPUT_WEIGHT',
  'INPUT_WEIGHT_PERFORMANCE',
  'AVERAGE_BATCH_SIZE',
  'NUMBER_OF_BATCHES',
  'NUMBER_OF_GOOD_BATCHES',
  'NUMBER_OF_BAD_BATCHES',
  'NUMBER_OF_BATCHES_WITH_FAILED_GOALS',
  'UPTIME',
  'IDLE_TIME',
  'UPTIME_PERCENTAGE',
  'IDLE_TIME_PERCENTAGE',
  'PRODUCTION_TIME',
  'DOWNTIME',
  'TOTAL_RUNTIME',
  'OEE',
  'OEE_AVAILABILITY',
  'OEE_QUALITY',
  'OEE_PERFORMANCE',
  'END_TEMPERATURE_STANDARD_DEVIATION',
  'GAS_CONSUMPTION',
  'AIR_CONSUMPTION',
  'ELECTRICITY_CONSUMPTION',
  'WATER_CONSUMPTION',
  'GAS_CONSUMPTION_PER_100KG',
  'WATER_CONSUMPTION_PER_100KG',
  'AIR_CONSUMPTION_PER_100KG',
  'ELECTRICITY_CONSUMPTION_PER_100KG',
  'SHRINKAGE',
] as const;

export type KpiType = typeof KpiTypes[number];

export function getIconForKpiType(kpiType: KpiType) {
  let map: { [key in KpiType]: string } = {
    TOTAL_INPUT_WEIGHT: 'kpi-types/total-input-weight',
    INPUT_WEIGHT_PERFORMANCE: 'kpi-types/input-weight-performance',
    AVERAGE_BATCH_SIZE: 'kpi-types/total-input-weight',
    NUMBER_OF_BATCHES: 'kpi-types/number-of-batches',
    NUMBER_OF_GOOD_BATCHES: 'kpi-types/number-of-good-batches',
    NUMBER_OF_BAD_BATCHES: 'kpi-types/number-of-bad-batches',
    NUMBER_OF_BATCHES_WITH_FAILED_GOALS: 'kpi-types/number-of-bad-batches',
    IDLE_TIME: 'kpi-types/idle-time',
    UPTIME: 'kpi-types/uptime',
    UPTIME_PERCENTAGE: 'kpi-types/uptime',
    IDLE_TIME_PERCENTAGE: 'kpi-types/idle-time',
    PRODUCTION_TIME: 'kpi-types/uptime',
    DOWNTIME: 'kpi-types/idle-time',
    TOTAL_RUNTIME: 'kpi-types/total-runtime',
    OEE: 'kpi-types/oee',
    OEE_AVAILABILITY: 'kpi-types/oee',
    OEE_QUALITY: 'kpi-types/oee',
    OEE_PERFORMANCE: 'kpi-types/oee',
    END_TEMPERATURE_STANDARD_DEVIATION:
      'kpi-types/end-temperature-standard-deviation',

    GAS_CONSUMPTION: 'kpi-types/gas-consumption',
    AIR_CONSUMPTION: 'kpi-types/air-consumption',
    ELECTRICITY_CONSUMPTION: 'kpi-types/electricity-consumption',
    WATER_CONSUMPTION: 'kpi-types/water-consumption',
    GAS_CONSUMPTION_PER_100KG: 'kpi-types/gas-consumption',
    AIR_CONSUMPTION_PER_100KG: 'kpi-types/air-consumption',
    ELECTRICITY_CONSUMPTION_PER_100KG: 'kpi-types/electricity-consumption',
    WATER_CONSUMPTION_PER_100KG: 'kpi-types/water-consumption',
    SHRINKAGE: 'kpi-types/shrinkage',
  };

  return map[kpiType];
}

export class KpiTypesEnums extends EnumsBase<KpiType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in KpiType]: string } = {
      TOTAL_INPUT_WEIGHT: l10n.t('Total input weight'),
      INPUT_WEIGHT_PERFORMANCE: l10n.t('Input weight performance'),
      AVERAGE_BATCH_SIZE: l10n.t('Avg. batch size'),
      NUMBER_OF_BATCHES: l10n.t('# of batches'),
      NUMBER_OF_GOOD_BATCHES: l10n.t('# of good batches'),
      NUMBER_OF_BAD_BATCHES: l10n.t('# of bad batches'),
      NUMBER_OF_BATCHES_WITH_FAILED_GOALS: l10n.t(
        '# of batches with failed goals'
      ),
      IDLE_TIME: l10n.t('Idle time'),
      UPTIME: l10n.t('Uptime'),
      UPTIME_PERCENTAGE: l10n.t('Uptime (%)'),
      IDLE_TIME_PERCENTAGE: l10n.t('Idle time (%)'),
      PRODUCTION_TIME: l10n.t('Production time'),
      DOWNTIME: l10n.t('Downtime'),
      TOTAL_RUNTIME: l10n.t('Total runtime'),
      OEE: l10n.t('OEE (%)'),
      OEE_AVAILABILITY: l10n.t('OEE Availability (%)'),
      OEE_QUALITY: l10n.t('OEE Quality (%)'),
      OEE_PERFORMANCE: l10n.t('OEE Performance (%)'),
      END_TEMPERATURE_STANDARD_DEVIATION: l10n.t('End temp. deviation'),
      GAS_CONSUMPTION: l10n.t('Gas consumption'),
      AIR_CONSUMPTION: l10n.t('Air consumption'),
      ELECTRICITY_CONSUMPTION: l10n.t('Electricity consumption'),
      WATER_CONSUMPTION: l10n.t('Water consumption'),
      GAS_CONSUMPTION_PER_100KG: l10n.t('Gas usage / 100kg'),
      WATER_CONSUMPTION_PER_100KG: l10n.t('Water usage  / 100kg'),
      AIR_CONSUMPTION_PER_100KG: l10n.t('Air usage / 100kg'),
      ELECTRICITY_CONSUMPTION_PER_100KG: l10n.t('Electricity usage / 100kg'),
      SHRINKAGE: l10n.t('Shrinkage'),
    };

    this.labels = labels;
  }
}

export class KpiTypesDescriptionsEnums extends EnumsBase<KpiType> {
  constructor(...rest: any) {
    super(...rest);

    let { l10n } = this;
    let labels: { [key in KpiType]: string } = {
      TOTAL_INPUT_WEIGHT: l10n.t('The total input weight used.'),

      INPUT_WEIGHT_PERFORMANCE: l10n.t(
        'The average input weight used per hour the machine was running.'
      ),

      AVERAGE_BATCH_SIZE: l10n.t('The average input weight used per batch.'),

      NUMBER_OF_BATCHES: l10n.t('The total number of batches roasted.'),

      NUMBER_OF_GOOD_BATCHES: l10n.t(
        'The number of batches roasted without problems.'
      ),

      NUMBER_OF_BAD_BATCHES: l10n.t('How many batches had an issue.'),

      NUMBER_OF_BATCHES_WITH_FAILED_GOALS: l10n.t(
        'The number of batches roasted that had at least one failed parameter goal.'
      ),

      IDLE_TIME: l10n.t('The time the roaster was not running.'),

      UPTIME: l10n.t('The time the roaster was running.'),

      UPTIME_PERCENTAGE: l10n.t('The % of time the roaster was running.'),

      IDLE_TIME_PERCENTAGE: l10n.t(
        'The % of time the roaster was not running.'
      ),

      PRODUCTION_TIME: l10n.t(
        'The time the roaster was actively producing coffee. This uses the status info from the status map, when available.'
      ),

      DOWNTIME: l10n.t(
        'The time the roaster was not producing. This uses the status info from the status map, when available.'
      ),

      TOTAL_RUNTIME: l10n.t(
        'The time the roaster was turned on. This uses the status info from the status map'
      ),

      OEE: l10n.t('The overall equipment efficiency.'),

      OEE_AVAILABILITY: l10n.t(
        'The time ratio between the roaster production time and its total runtime'
      ),

      OEE_QUALITY: l10n.t('The % of good batches in your production.'),

      OEE_PERFORMANCE: l10n.t(
        'The ratio between roaster actual output and their theoretical maximum output'
      ),

      END_TEMPERATURE_STANDARD_DEVIATION: l10n.t(
        `The standard deviation of the end temperatures (in relation to the average end temperature). 
This tells you how big the spread of end temperatures has been.`
      ),

      GAS_CONSUMPTION: l10n.t(
        'The total gas consumption, including main burner & after burner.'
      ),

      AIR_CONSUMPTION: l10n.t('The total air consumption.'),

      ELECTRICITY_CONSUMPTION: l10n.t('The total electricity consumption.'),

      WATER_CONSUMPTION: l10n.t('The total water consumption.'),

      GAS_CONSUMPTION_PER_100KG: l10n.t(
        'The total gas consumption, including main burner & after burner, per 100kg of used green coffee.'
      ),

      AIR_CONSUMPTION_PER_100KG: l10n.t(
        'The total air consumption, per 100kg of used green coffee.'
      ),

      ELECTRICITY_CONSUMPTION_PER_100KG: l10n.t(
        'The total electricity consumption, per 100kg of used green coffee.'
      ),

      WATER_CONSUMPTION_PER_100KG: l10n.t(
        'The total water consumption, per 100kg of used green coffee.'
      ),

      SHRINKAGE: l10n.t(
        'The difference between output and input weight of a batch.'
      ),
    };

    this.labels = labels;
  }
}

// If this KPI type is aggregated, meaning that the per recipe/machine KPIs can be summed to get the total
// For example for averages or percentages, this does not work.
export function isAggregated(kpiType: KpiType) {
  return ![
    'END_TEMPERATURE_STANDARD_DEVIATION',
    'AVERAGE_BATCH_SIZE',
    'INPUT_WEIGHT_PERFORMANCE',
    'UPTIME_PERCENTAGE',
    'IDLE_TIME_PERCENTAGE',
    'OEE',
    'OEE_AVAILABILITY',
    'OEE_QUALITY',
    'OEE_PERFORMANCE',
  ].includes(kpiType);
}

export function isNotOeeKpi(kpiType: KpiType) {
  return ![
    'OEE',
    'OEE_AVAILABILITY',
    'OEE_QUALITY',
    'OEE_PERFORMANCE',
  ].includes(kpiType);
}

export function isOeeKpi(kpiType: KpiType) {
  return ['OEE', 'OEE_AVAILABILITY', 'OEE_QUALITY', 'OEE_PERFORMANCE'].includes(
    kpiType
  );
}
