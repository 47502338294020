import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import AuthenticatedRoute from 'fabscale-app/routes/base/-base-authenticated-route';
import { PermissionError } from 'fabscale-app/models/errors/permission-error';
import RoastBatchesIndexController from './index/controller';
import UserSessionService from 'fabscale-app/services/user-session';

export default class RoutesRoastPic extends AuthenticatedRoute {
  @service userSession: UserSessionService;
  @service l10n: L10nService;

  afterModel() {
    if (!this.userSession.permissionMap.ROASTPIC_VIEW) {
      throw new PermissionError(
        this.l10n.t("You don't have the permission to view roast-pic.")
      );
    }
  }

  deactivate() {
    let controller = this.controllerFor(
      'routes/roast-pic.index'
    ) as RoastBatchesIndexController;

    next(() => {
      controller.dateRange = undefined;
      controller.timeRange = undefined;
      controller.coffeeTypes = undefined;
      controller.page = undefined;
      controller.pageSize = undefined;
      controller.sortBy = undefined;
      controller.sortDirection = undefined;
    });
  }
}
