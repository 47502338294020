import Component from '@glimmer/component';
import { LabSample } from 'fabscale-app/models/lab-sample';
import { DateTime } from 'luxon';
import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';

export type RoastPicDetailPageTab = 'PICTURE_DATA';

interface Args {
  labSample: LabSample;
}

export default class RoastPicDetailsPage extends Component<Args> {
  get currentTab(): RoastPicDetailPageTab {
    return 'PICTURE_DATA';
  }

  get formattedCreatedDate() {
    const date = deserializeDate(this.args.labSample.createdDate as any);
    return `Recorded at ${date.toLocaleString(DateTime.DATETIME_SHORT)}`;
  }
}
