import { DateTimeString, PageInfo } from 'fabscale-app';

import { deserializeDate } from 'fabscale-app/utilities/utils/serialize-date';
import { DateTime } from 'luxon';

export class LabSample {
  id: string;
  createdDate: DateTime;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatchNumber?: string;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  numberDefects?: number;
  numberOfBeans?: number;

  constructor(dto: LabSampleDto) {
    this.id = dto.id;
    this.createdDate = deserializeDate(dto.createdDate);
    this.pictureUrl = dto.pictureUrl;
    this.lotNumber = dto.lotNumber;
    this.roastBatchNumber = dto.roastBatchNumber;
    this.coffeeType = dto.coffeeType;
    this.coffeeVariety = dto.coffeeVariety;
    this.screenSizeMode = dto.screenSizeMode;
    this.gourmetColorAvg = dto.gourmetColorAvg;
    this.gourmetColorDeviation = dto.gourmetColorDeviation;
    this.numberDefects = dto.numberDefects;
    this.numberOfBeans = dto.numberOfBeans;
  }
}

export interface LabSampleDto {
  id: string;
  createdDate: DateTimeString;
  pictureUrl?: string;
  lotNumber?: string;
  roastBatchNumber?: string;
  coffeeType?: string;
  coffeeVariety?: string;
  screenSizeMode?: number;
  gourmetColorAvg?: number;
  gourmetColorDeviation?: number;
  numberDefects?: number;
  numberOfBeans?: number;
}

export interface PaginatedLabSamples {
  items: LabSample[];
  pageInfo: PageInfo;
}
