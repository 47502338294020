import Component from '@glimmer/component';
import { RoastPicDetailPageTab } from '.';

interface Args {
  currentTab: RoastPicDetailPageTab;
}

export default class RoastPicDetailsTabBar extends Component<Args> {
  get isActive() {
    let { currentTab } = this.args;

    return {
      pictureData: currentTab === 'PICTURE_DATA',
    };
  }
}
